<template>
  <div
    v-if="guide"
    :class="{
      'embed-responsive embed-responsive-16by9 -h5p': guide.type === 'h5p',
      'embed-responsive embed-responsive-16by9': guide.type != 'h5p',
    }"
    class="d-flex justify-content-center align-items-center"
  >
    <iframe
      v-if="
        guide.type === 'youtube' ||
        guide.type === 'gdrive' ||
        guide.type === 'h5p'
      "
      ref="iplr"
      :src="guide.url"
      class="embed-responsive-item"
      frameborder="0"
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      @load="loadedIframe"
    ></iframe>
    <img
      v-else-if="guide.type === 'image'"
      :src="guide.url"
      class="cover-image"
    />
    <a
      v-else-if="guide.type !== undefined && guide.type"
      :href="guide.url"
      target="_blank"
    >
      {{ guide.url }}
    </a>
    <img
      v-else
      class="no-video-image"
      src="/noVideo.png"
      alt="no-video
		"
    />
  </div>
</template>

<script>
import YTPlayer from "yt-player";
import { onVideoStart, onVideoSeek } from "/utils/watchtimeTracker.service";

const TIME_TRACKING_INTERVAL_IN_MS = 1000;
const TIME_TRACKING_ACCEPTABLE_DEVIATION_IN_SECONDS = 0.2;
const MIN_TIME_TRACKING_INTERVAL_IN_SECONDS =
  TIME_TRACKING_INTERVAL_IN_MS / 1000 -
  TIME_TRACKING_ACCEPTABLE_DEVIATION_IN_SECONDS;
const MAX_TIME_TRACKING_INTERVAL_IN_SECONDS =
  TIME_TRACKING_INTERVAL_IN_MS / 1000 +
  TIME_TRACKING_ACCEPTABLE_DEVIATION_IN_SECONDS;

export default {
  props: ["lesson"],

  data() {
    return {
      guide: null,
      player: null,
      intervalHandle: null,
      previousVideoTimeInSeconds: 0,
      watchTimeInSeconds: 0,
    };
  },

  watch: {
    async lesson(data, oldData) {
      // When window is reloaded
      if (data) {
        if (oldData) {
          this.guide = null;
          await this.$nextTick();
        }
        this.trackWatchTime();
        clearInterval(this.intervalHandle);
        this.intervalHandle = null;
        this.loadVideo(data);
      }
    },
  },

  mounted() {
    window.addEventListener("beforeunload", this.onBeforeUnload);
    this.loadVideo(this.lesson);
  },

  beforeDestroy() {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
    }

    window.removeEventListener("beforeunload", this.onBeforeUnload);
    this.trackWatchTime();
  },

  methods: {
    loadVideo(lesson) {
      this.guide = lesson._guide;

      // Set events if youtube video is present
      if (this.guide.type === "youtube")
        setTimeout(() => {
          this.player = new YTPlayer(this.$refs.iplr);
          this.player.load(this.guide.url.match(/(?:embed\/)(.*)(?=\?)/)[1]);

          this.intervalHandle = setInterval(() => {
            const currentTimeDiff =
              this.player.getCurrentTime() - this.previousVideoTimeInSeconds;
            if (
              currentTimeDiff >= MIN_TIME_TRACKING_INTERVAL_IN_SECONDS &&
              currentTimeDiff <= MAX_TIME_TRACKING_INTERVAL_IN_SECONDS
            ) {
              this.watchTimeInSeconds += currentTimeDiff;
            }
            this.previousVideoTimeInSeconds = this.player.getCurrentTime();
          }, TIME_TRACKING_INTERVAL_IN_MS);
        }, 3000);

      // Set event if H5P video is present
      if (this.guide.type === "h5p") {
        window.removeEventListener("message", this._onMessage);
        window.addEventListener("message", this._onMessage);
      }
    },

    beforeUnloadFn() {
      this.trackWatchTime();
    },

    trackWatchTime() {
      if (this.intervalHandle && this.watchTimeInSeconds) {
        this.$eventLogger.userLog(`lesson.video.watch`, {
          lesson_slug: this.lesson.slug,
          watch_time: Math.floor(this.watchTimeInSeconds),
        });
        this.previousVideoTimeInSeconds = 0;
        this.watchTimeInSeconds = 0;
      }
    },

    _onMessage(event) {
      const { data } = event;

      if (!data.verb) return;

      switch (data.verb.display["en-US"]) {
        case "answered":
          this.$eventLogger.userLog("lesson.video.challenge.answer", {
            ...data.result,
          });
          break;
        case "completed":
          this.$eventLogger.userLog("lesson.video.challenge.completed");
          break;
        case "play":
          onVideoStart(this.lesson.attributes.slug, this.player);
          break;
        case "pause":
          onVideoSeek(this.player);
      }
    },

    loadedIframe() {
      // setTimeout(() => {
      // 	this.$refs.iplr.contentWindow.postMessage(
      // 		'{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*'
      // 	)
      // }, 600)//debounce thumbnail load
    },

    fireVideoEvent(eventName) {
      this.$eventLogger.userLog(`lesson.video.${eventName}`, {
        lesson_slug: this.lesson.attributes.slug,
        elapsed_time: this.player.getCurrentTime(),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.embed-responsive-16by9 {
  &.-h5p {
    &::before {
      padding-top: calc(56.25% + 54px);
    }
  }
}

.embed-responsive {
  a {
    font-size: 20px;
    color: #ffffff;
    text-decoration: underline;
  }

  img.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .no-video-image {
    height: 70px;
  }
}
</style>

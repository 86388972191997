import { eventLogger } from "./event";
import YTPlayer from "yt-player";

let ACCUMULATOR = 0;
let STARTED_TRACKING_AT: number,
  MONITORING_INTERVAL: number,
  LAST_CALCULATED_TIME: number,
  IS_PROCESSING_REQUEST: boolean;

// Helpers
const calcTimeSpent = (player: YTPlayer) => {
  LAST_CALCULATED_TIME = Math.abs(
    player.getCurrentTime() - STARTED_TRACKING_AT + ACCUMULATOR
  );
  return LAST_CALCULATED_TIME;
};

//Exports
export const onVideoStart = (lessonSlug: string, player: YTPlayer) => {
  STARTED_TRACKING_AT = player.getCurrentTime();

  MONITORING_INTERVAL = setInterval(() => {
    const timeSpent = calcTimeSpent(player);
    if (timeSpent > 30) {
      IS_PROCESSING_REQUEST = true;
      eventLogger.userLog("lesson.video.watchTime", {
        lesson_slug: lessonSlug,
        time_spent: timeSpent,
        videoTime: player.getCurrentTime(),
      });
      ACCUMULATOR = 0;
      STARTED_TRACKING_AT = player.getCurrentTime();
    }
  }, 1000);
};

export const onVideoSeek = (_player: YTPlayer) => {
  ACCUMULATOR = LAST_CALCULATED_TIME;
  clearInterval(MONITORING_INTERVAL);
};

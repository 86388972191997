var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.guide)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",class:{
      'embed-responsive embed-responsive-16by9 -h5p': _vm.guide.type === 'h5p',
      'embed-responsive embed-responsive-16by9': _vm.guide.type != 'h5p',
    }},[(
        _vm.guide.type === 'youtube' ||
        _vm.guide.type === 'gdrive' ||
        _vm.guide.type === 'h5p'
      )?_c('iframe',{ref:"iplr",staticClass:"embed-responsive-item",attrs:{"src":_vm.guide.url,"frameborder":"0","allow":"accelerometer; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""},on:{"load":_vm.loadedIframe}}):(_vm.guide.type === 'image')?_c('img',{staticClass:"cover-image",attrs:{"src":_vm.guide.url}}):(_vm.guide.type !== undefined && _vm.guide.type)?_c('a',{attrs:{"href":_vm.guide.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.guide.url)+" ")]):_c('img',{staticClass:"no-video-image",attrs:{"src":"/noVideo.png","alt":"no-video\n\t\t"}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }